/* eslint-disable prefer-const */
import _isEmpty from 'lodash/isEmpty'
import _noop from 'lodash/noop'
import PiwikReactRouter from 'piwik-react-router'

// eslint-disable-next-line import/no-mutable-exports
let Tracker = {
  _isShim: true,
  track: _noop,
  push: _noop,
  setUserId: _noop,
  trackError: _noop,
  connectToHistory: _noop,
  disconnectFromHistory: _noop,
}

function configure({
  config = {},
  condition = DefaultCondition(),
}) {
  if (!condition || _isEmpty(config)) {
    return Tracker
  }

  Tracker = new PiwikReactRouter(config)

  return Tracker
}

function DefaultCondition() {
  return process.env.NODE_ENV === 'production'
}

export { Tracker, configure }
