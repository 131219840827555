import { makeStyles } from '@coinspaid-hub/ui-kit/src/functions'

export default makeStyles(({
  spacing,
  palette,
  typography,
}) => ({
  qrCodeWrapperClass: {
    position: 'absolute',
    top: spacing(5),
    boxShadow: palette.shadows.hover,
    borderRadius: typography.pxToRem(8),
    overflow: 'hidden',
  },
}))
