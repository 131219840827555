import PropTypes from 'prop-types'

import template from './template'

const GoBackLink = (props) => template({
  ...props,
})

GoBackLink.propTypes = {
  link: PropTypes.string,
}

export default GoBackLink
