import { makeStyles } from '@coinspaid-hub/ui-kit/src/functions'

export default makeStyles(({
  spacing,
  palette,
}) => ({
  header: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    borderBottom: `1px solid ${palette.basic[200]}`,
    paddingTop: spacing(1),
    paddingBottom: spacing(1),
    paddingLeft: spacing(5),
    paddingRight: spacing(3.5),
  },
}))
