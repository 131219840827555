import { makeStyles } from '@coinspaid-hub/ui-kit/src/functions'

export default makeStyles(({
  spacing,
  typography,
}) => ({
  logo: {
    marginRight: spacing(2),
    width: typography.pxToRem(20),
    height: typography.pxToRem(20),
    borderWidth: 0,
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
}))
