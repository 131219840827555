import { makeStyles } from '@coinspaid-hub/ui-kit/src/functions'

export default makeStyles((theme) => ({
  link: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(24),
    textAlign: 'center',
    color: theme.palette.basic[800],
    textDecoration: 'none',
    marginTop: theme.spacing(2),
  },
}))
