import React from 'react'
import { Helmet } from 'react-helmet'

import { useTheme } from '@coinspaid-hub/ui-kit/src/functions'

const ThemeImage = () => {
  const theme = useTheme()

  return (
    <Helmet
      title={theme.title.app}
      link={[
        {
          rel: 'shortcut icon',
          type: 'image/png',
          href: theme.images.app.favicon.component,
        },
      ]}
    />
  )
}

export default ThemeImage
