import { makeStyles } from '@coinspaid-hub/ui-kit/src/functions'

export default makeStyles(({
  palette,
}) => ({
  copyIconClass: {
    '& svg path': {
      fill: palette.basic[600],
    },
  },
}))
