import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { Tracker, configure } from 'utils/analitics'

const Tracking = () => {
  const location = useLocation()

  useEffect(() => {
    configure({
      config: window.app_config?.matomo || {},
    })

    Tracker.push(['setCustomDimension', 1, !!window.ethereum])
  }, [])

  useEffect(() => {
    Tracker.track(location)
  }, [location])

  return null
}

export default Tracking
