import React from 'react'

import Trans from 'components/Trans'

import styles from './styles'

export default function template({
  link,
}) {
  const classes = styles()

  return (
    <a
      href={link}
      className={classes.link}
    >
      <Trans
        i18nKey='forms:buttons.go_back'
        defaults='Go back'
      />
    </a>
  )
}
