import { makeStyles } from '@coinspaid-hub/ui-kit/src/functions'

export default makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0),
  },
  modal: {
    borderRadius: theme.typography.pxToRem(16),
    boxShadow: `0px 7px 64px ${theme.palette.basic[100]}`,
    background: theme.palette.bg,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
  },
  body: {
    flex: 1,
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  borderRadius: {
    borderRadius: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(8)} 0 0`,
  },
  name: {
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(40),
    color: theme.palette.basic[900],
  },
  greyArea: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    height: theme.typography.pxToRem(192),
    backgroundColor: theme.palette.basic[100],
  },
  form: {
    paddingTop: theme.spacing(8),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingBottom: theme.spacing(3),

    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  fieldWrapper: {
    marginTop: theme.spacing(3),
  },
  fieldClass: {
    backgroundColor: theme.palette.background.paper,
  },
  buttonWrapper: {
    marginTop: theme.spacing(3),
  },
  button: {
    background: theme.palette.basic[700],
    color: theme.palette.background.invoice,
  },
  icon: {
    height: theme.typography.pxToRem(24),
    width: theme.typography.pxToRem(24),
  },
  amountNotification: {
    paddingTop: theme.spacing(3),
  },
  text: {
    fontWeight: 400,
    color: theme.palette.basic[700],
    paddingBottom: theme.spacing(1),
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(24),
  },
  footer: {
    justifyContent: 'space-between',
    display: 'flex',

    borderTop: `1px solid ${theme.palette.basic[200]}`,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    marginTop: theme.spacing(4),

    [theme.breakpoints.down('xs')]: {
      marginTop: 'auto',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },

    '& p': {
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(24),
      color: theme.palette.basic[700],
    },
  },
  langs: {
    display: 'flex',
  },
  langSwitch: {
    paddingTop: 0,
    paddingBottom: 0,
    color: theme.palette.basic[700],
  },
  active: {
    color: theme.palette.basic[900],
  },
  textBold: {
    fontWeight: 600,
    color: theme.palette.basic[900],
  },
  alertList: {
    listStyleType: '"· "',
    paddingLeft: theme.typography.pxToRem(8),
  },
}))
