import React from 'react'

import { Grid, Typography } from '@coinspaid-hub/ui-kit'

import GoBackLink from '../component/GoBackLink'

import styles from './styles'

export default function template(props) {
  const classes = styles()
  const {
    data,
    t,
  } = props

  return (
    <Grid
      container
      direction='column'
      className={classes.container}
    >
      <Typography
        variant='h3'
        className={classes.title}
      >
        {t('views:error.smth_wrong', 'Something went wrong')}
      </Typography>
      <Typography
        variant='subtitle3'
        className={classes.text}
      >
        {t('views:error.try_again', 'Please try again later')}
      </Typography>
      {data?.url_back ? <GoBackLink link={data.url_back} /> : null}
    </Grid>
  )
}
